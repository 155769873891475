// overwrite react-tooltip css
:root {
  --rt-opacity: 0.8 !important;
  --rt-color-white: #fff !important;
  --rt-color-dark: #020626 !important;
  // --rt-color-success: #8dc572;
  // --rt-color-error: #be6464;
  // --rt-color-warning: #f0ad4e;
  // --rt-color-info: #337ab7;
  // --rt-transition-show-delay: 0.15s;
  // --rt-transition-closing-delay: 0.15s;
}

$azure-blue: "#007AFF";
$whitish-blue: "#F5F9FE";
$light-black: "#121212";
$white: #fff;
$deep-orange: "#FF6301";
$classic-orange: "#FF6200";
$yellowish-orange: "#FF9F1A";
$cold-yellow: "#ffd864";
$faded-yellow: "#fff0bc";
$cobalt-blue: "#0457BF";
$sapphire-blue: "#0054BE";
$jade-green: "#008F87";
$mint-green: "#10CBC0";

$background: $whitish-blue;
$primary: $azure-blue;
$primary-background: $azure-blue;
$primary-foreground: $white;
$secondary-background: $white;
