@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap");

@layer base {
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    list-style: none;
  }

  html {
    font-family: "Space Grotesk", sans-serif;
    scroll-behavior: smooth;
    box-sizing: border-box;
    font-size: 100%; // 1rem = 16px!
  }

  body {
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 122, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 122, 255, 0.5);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 122, 255, 0.7);
}

.chart-tooltip {
  color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  min-width: 80px;
  border-radius: var(--numbers-rem-075, 12px);
  background: var(--dark-blue-08, rgba(2, 6, 38, 0.8));

  .label {
    color: var(--White, #fff);
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    opacity: 0.75;
  }

  .value-container {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .value {
      color: var(--White, #fff);
      font-family: "Space Grotesk";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    .currency {
      color: var(--White, #fff);
      font-family: "Space Grotesk";
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px; /* 100% */
      opacity: 0.6;
    }
  }
}

.collapsible-row {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  display: none;
  transition: transform 0.3s ease-out, max-height 0.3s ease-out,
    opacity 0.5s ease-out;
}

.collapsible-row.open {
  transform: scaleY(1);
  display: table-row;
  max-height: 1000px;
  overflow: auto;
  opacity: 1;
  transition: transform 0.3s ease-in, max-height 0.3s ease-in,
    opacity 0.5s ease-in;
}

.scrollbar-gutter {
  scrollbar-gutter: stable;
}

.body-no-scroll {
  overflow: hidden;
  height: 100%;
}

.tooltip-hover {
  position: relative;
}

@media (min-width: 768px) {
  .tooltip-contents {
    visibility: hidden;
    word-break: normal;
    min-width: 120px;
    max-width: 180px;
    position: absolute;
    transform: translate(-80%, -85%);
    top: -10px;
    left: 105%;
    background-color: #020626cc;
    color: #ffff;
    text-align: center;
    padding: 6px 12px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    font-size: 13px;
    opacity: 0;
    transition: opacity 0.3s linear, visibility 0.3s linear;
    transition-delay: 0s;

    &:after {
      content: "";
      position: absolute;
      border: 1px solid #353851;
      border-color: transparent #353851;
      border-width: 13px 0 13px 12px;
      bottom: -17px;
      left: 12px;
      transform: rotate(90deg);
    }
  }

  .tooltip-hover:hover .tooltip-contents {
    visibility: visible;
    opacity: 1;
    transition-delay: 1s;
    z-index: 666;
  }
}

@media (max-width: 768px) {
  .tooltip-contents {
    display: none;
  }
}

.secondary-cards-section {
  box-shadow: 0 0 0 100vmax white;
  clip-path: inset(0 -100vmax);
}
